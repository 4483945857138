import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import illu from '../../../images/illu-attribution-current.png';

const features = [
  {
    name: "Rémunération d'un seul affilié",
    description: 'seulement le tout dernier affilié reçoit une commission.',
  },
  {
    name: 'Concentration de profil affilié identique',
    description:
      "augmentation des profils d'affiliés présents en fin de parcours.",
  },
  {
    name: 'Fuites des affiliés présents en début de parcours',
    description:
      "ces affiliés mettent désormais en avant d'autres programmes d'affiliation plus rémunérateurs, comme celui d'Amazon.",
  },
  {
    name: 'Pas de prise en compte du mix marketing',
    description:
      "qu'il y ait des sources de trafic payantes ou non dans le parcours, le montant de la commission est toujours le même.",
  },
  {
    name: 'Explosion des coûts',
    description:
      "l'affiliation devient un levier présent dans la majorité des parcours sans pour autant baisser les budgets des autres leviers.",
  },
  {
    name: 'Déduplication Last Click',
    description:
      "à la recherche d'économie, le responsable acquisition se tourne vers un modèle contre performant avec une déduplication au last click.",
  },
];

export default function Example() {
  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className=" mx-auto text-center">
          <p className="mt-4 font-title text-xl sm:text-3xl text-gray-900 font-extrabold">
            Le modèle d'attribution de votre plateforme d'affiliation : le
            dernier affilié gagne tout
          </p>
        </div>
        <img src={illu} alt="Attribution" />
        <h2 className="mt-4 sm:mt-0 text-xl sm:text-3xl font-title font-extrabold text-gray-900 text-center">
          Cela pose de multiples problèmes
        </h2>
        <dl className="mt-8 sm:mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-2 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="relative">
              <dt>
                <AiOutlineClose
                  className="absolute h-6 w-6 text-red-500"
                  aria-hidden="true"
                />
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-gray-500">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
